import React from 'react';
import SEO from '../components/SEO';
import Placeholder from '../containers/placeholder/placeholder';

export default () => (
  <>
    <SEO
      title="Software Engineer"
      keywords={[
        'emery',
        'muhozi',
        'developer',
        'learner',
        'software',
        'engineer',
        'Tech',
        'Enthusiast',
      ]}
    />
    <Placeholder />
  </>
);
