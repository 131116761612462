import React, { FC } from 'react';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

import SchemaOrg from './SchemaOrg';
import favicon from '../../assets/images/icons/favicon.ico';
import appleTouchIcon from '../../assets/images/icons/apple-touch-icon.png';
import maskIcon from '../../assets/images/icons/safari-pinned-tab.svg';
import favicon16 from '../../assets/images/icons/favicon-16x16.png';
import favicon32 from '../../assets/images/icons/favicon-32x32.png';

interface Props {
  lang?: string;
  title: string;
  description?: string;
  meta?: any[];
  keywords?: string[];
  isBlogPost?: boolean;
  postData?: string;
  frontmatter?: Object;
  postImage?: string;
}

export const SEO: FC<Props> = ({
  lang = 'en',
  title,
  description = '',
  meta = [],
  keywords = [],
  isBlogPost = false,
  postData = '',
  frontmatter = {},
  postImage = '',
}) => {
  const {
    site: { siteMetadata },
    profileImage,
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author {
              name
              jobTitle
              social {
                twitter
              }
            }
            siteUrl
          }
        }
        profileImage: file(publicURL: {}, name: { eq: "profile" }) {
          publicURL
        }
      }
    `,
  );

  const metaDescription = description || siteMetadata.description;

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={title}
        titleTemplate={`%s - ${siteMetadata.title}`}
        link={[
          {
            rel: 'apple-touch-icon',
            sizes: '180x180',
            href: appleTouchIcon,
          },
          {
            rel: 'icon',
            type: 'image/png',
            sizes: '32x32',
            href: favicon32,
          },
          {
            rel: 'icon',
            type: 'image/png',
            sizes: '16x16',
            href: favicon16,
          },
          {
            rel: 'mask-icon',
            color: '#5bbad5',
            href: `${maskIcon}`,
          },
          { rel: 'shortcut icon', href: `${favicon}` },
        ]}
        meta={[
          {
            name: `description`,
            content: metaDescription,
          },
          {
            property: `og:title`,
            content: `${title} - ${siteMetadata.title}`,
          },
          {
            property: `og:description`,
            content: metaDescription,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:creator`,
            content: siteMetadata.author.social.twitter,
          },
          {
            name: `twitter:title`,
            content: title,
          },
          {
            name: `twitter:description`,
            content: metaDescription,
          },
          {
            name: `msapplication-TileColor`,
            content: `#e8cdc5`,
          },
          {
            name: `theme-color`,
            content: `#ffffff`,
          },
        ]
          .concat(
            keywords.length > 0
              ? {
                  name: `keywords`,
                  content: keywords.join(`, `),
                }
              : [],
          )
          .concat(meta)}
      />
      <SchemaOrg
        isBlogPost={isBlogPost}
        url={siteMetadata.siteUrl}
        title={title}
        image={profileImage}
        description={description}
        canonicalUrl={siteMetadata.siteUrl}
        author={siteMetadata.author.name}
        defaultTitle={siteMetadata.title}
      />
    </>
  );
};

export default SEO;
