import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import { Envelope, Linkedin, Github, Twitter } from 'react-bootstrap-icons';
import './placeholder.scss';

const profileImage = '../../assets/images/profile.jpeg';

export default () => {
  return (
    <div className="wrapper">
      <div className="body">
        <div className="profile">
          <div className="profile-image-section">
            <StaticImage
              src={profileImage}
              className="profile-img"
              alt="Emery Muhozi"
            />
          </div>
          <p className="about">Hey, I'm Emery</p>
          <p className="title">
            Software Engineer at Datalabs, Tapestry Inc,
            <br />
            (COACH - KATE SPADE - STUART WEITZMAN)
          </p>
          <div className="social-icons">
            <OutboundLink
              href="mailto:hey@muhozi.com"
              target="__blank"
              className="icon"
            >
              <Envelope />
            </OutboundLink>
            <OutboundLink
              href="https://www.linkedin.com/in/muhozi"
              target="__blank"
              className="icon"
            >
              <Linkedin />
            </OutboundLink>
            <OutboundLink
              href="https://github.com/muhozi"
              target="__blank"
              className="icon"
            >
              <Github />
            </OutboundLink>
            <OutboundLink
              href="https://twitter.com/emeryquo"
              target="__blank"
              className="icon"
            >
              <Twitter />
            </OutboundLink>
          </div>
        </div>
      </div>
      <div className="footer">
        <i>
          <OutboundLink href="https://twitter.com/emeryquo" target="__blank">
            &copy; {new Date().getFullYear()} Emery
          </OutboundLink>
        </i>
      </div>
    </div>
  );
};
