import React, { FC } from 'react';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

interface Props {
  author?: {
    name: string;
    jobTitle: string;
    linkedIn: string;
    picture: string;
  };
  canonicalUrl?: string;
  datePublished?: string;
  defaultTitle?: string;
  description?: string;
  image?: string;
  isBlogPost: boolean;
  title: string;
  url: string;
}

const SchemaOrg: FC<Props> = React.memo(
  ({
    author,
    canonicalUrl,
    datePublished,
    defaultTitle,
    description,
    image,
    isBlogPost,
    title,
    url,
  }) => {
    const {
      site: { siteMetadata },
      profileImage,
    } = useStaticQuery(
      graphql`
        query {
          site {
            siteMetadata {
              title
              description
              author {
                name
                jobTitle
                social {
                  twitter
                  linkedInProfile
                  twitterProfile
                }
              }
              siteUrl
            }
          }
          profileImage: file(publicURL: {}, name: { eq: "profile" }) {
            publicURL
          }
        }
      `,
    );
    const baseSchema = [
      {
        '@context': 'http://schema.org',
        '@type': 'WebSite',
        url,
        name: `${siteMetadata.author.name} Profile`,
        alternateName: defaultTitle,
        publisher: {
          '@context': 'http://schema.org',
          '@type': 'Person',
          name: siteMetadata.author.name,
          url: siteMetadata.siteUrl,
          jobTitle: siteMetadata.author.jobTitle,
          image: siteMetadata.siteUrl + profileImage.publicURL,
          sameAs: [
            siteMetadata.author.social.linkedInProfile,
            siteMetadata.author.social.twitterProfile,
          ],
        },
      },
    ];

    const schema = isBlogPost
      ? [
          ...baseSchema,
          {
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            itemListElement: [
              {
                '@type': 'ListItem',
                position: 1,
                item: {
                  '@id': url,
                  name: title,
                  image,
                },
              },
            ],
          },
          {
            '@context': 'http://schema.org',
            '@type': 'BlogPosting',
            url,
            name: title,
            alternateName: defaultTitle,
            headline: title,
            image: {
              '@type': 'ImageObject',
              url: image,
            },
            description,
            author: {
              '@type': 'Person',
              name: author?.name,
            },
            publisher: {
              '@type': 'Person',
              name: author?.name,
              url: author?.linkedIn,
              jobTitle: author?.jobTitle,
              gender: 'male',
              image: author?.picture,
            },
            mainEntityOfPage: {
              '@type': 'WebSite',
              '@id': canonicalUrl,
            },
            datePublished,
          },
        ]
      : baseSchema;

    return (
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(schema)}</script>
      </Helmet>
    );
  },
);

export default SchemaOrg;
